import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { TextSection } from "./../styledcomponents/ChapterStyles";

const IndexPage = () => (
  <Layout>
    <TextSection>
      <h1>The Cat Detectives</h1>
      <p>
        Start with <Link to={`/chapter/1`}>Chapter 1</Link>. New chapters will
        be added as they are written and illustrated as they are illustrated.
      </p>
    </TextSection>
  </Layout>
);

export default IndexPage;
